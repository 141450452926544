export class AlgoParameter {
  static TYPES_TABLE = [
    { value: 'StratStartTime', type: 1, description: 'Strategy Start Time', fixTagId: 7400, values: null },
    { value: 'StratEndTime', type: 1, description: 'Strategy End Time', fixTagId: 7401, values: null },
    { value: 'MaxPctVolume', type: 2, description: 'Max Participation Volume (%)', fixTagId: 6064, values: null },
    { value: 'MinPctVolume', type: 2, description: 'Min Participation Volume (%)', fixTagId: 6067, values: null },
    { value: 'IWouldPrice', type: 2, description: 'I Would Price', fixTagId: 6208, values: null },
    { value: 'OnClosePercent', type: 2, description: 'On Close %', fixTagId: 6416, values: null },
    { value: 'StrategyStyle', type: 3, description: 'Strategy Style', fixTagId: 6171, values: JSON.stringify([
        {
          name: 'Passive',
          value: 0
        },
        {
          name: 'Neutral',
          value: 1
        },
        {
          name: 'Agressive',
          value: 2
        }
      ]) },
    { value: 'OnOpen', type: 3, description: 'On Open', fixTagId: 6206, values: JSON.stringify([
        {
          name: 'Yes',
          value: 'Y'
        },
        {
          name: 'No',
          value: 'N'
        }
      ]) },
    { value: 'OnClose', type: 3, description: 'On Close', fixTagId: 6207, values: JSON.stringify([
        {
          name: 'Yes',
          value: 'Y'
        },
        {
          name: 'No',
          value: 'N'
        }
      ]) }
  ];

  static FIELD_TYPES = [
    { value: 1, name: 'DateTime' },
    { value: 2, name: 'Number' },
    { value: 3, name: 'Dropdown' },
    { value: 4, name: 'Text' },

  ]
}
