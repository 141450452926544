import { Pipe, PipeTransform } from '@angular/core';
import { TransactionStatus } from '../constants/transaction-status';

@Pipe({
  name: 'transactionStatus'
})
export class TransactionStatusPipe implements PipeTransform {
  transform(transactionStatus: any): string {
    const status = TransactionStatus.ALL_STATUS.find(e => e.value === transactionStatus);
    if (status) {
      return status.label;
    }
    return '-';
  }
}
