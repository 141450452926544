/* eslint-disable @typescript-eslint/naming-convention */
export class TransactionStatus {
  static Status: string[] = [
    'Unknown',
    'New',
    'Pending',
    'Completed',
    'Cancelled',
    'Rejected',
    'Pending Cancel',
    'Partially Filled',
    'Done For Day',
    'Replaced',
    'Stopped',
    'Suspended',
    'Pending New',
    'Calculated',
    'Expired',
    'Accepted For Bidding',
    'Pending Replace',
    'Cancel Request',
    'Cancel Replace Request',
    'Pending Cancel Request',
    'Pending Cancel Replace Request'
  ];
  static ALL_STATUS = [
    { value: 0, label: 'Unknown', color: '#27A69A', palette: 'brand-cta',cancelable: true, replaced: true },
    { value: 1, label: 'New', color: '#27A69A', palette: 'brand-cta', cancelable: true, replaced: true },
    { value: 2, label: 'Pending', color: '#E1C84B', palette: 'system-yellow', cancelable: true, replaced: true },
    { value: 3, label: 'Completed', color: '#27A69A', palette: 'brand-cta', cancelable: false, replaced: false},
    { value: 4, label: 'Cancelled', color: '#CD3264', palette: 'system-pink', cancelable: false, replaced: false},
    { value: 5, label: 'Rejected', color: '#CD3264', palette: 'system-pink', cancelable: false, replaced: false},
    { value: 6, label: 'Pending Cancel', color: '#E1C84B', palette: 'system-yellow',  cancelable: true, replaced: true },
    { value: 7, label: 'Partially Filled', color: '#EF5351', palette: 'system-orange', cancelable: true, replaced: true },
    { value: 8, label: 'Done For Day', color: '#27A69A', palette: 'brand-cta', cancelable: false, replaced: false},
    { value: 9, label: 'Replaced', color: '#EF5351', palette: 'system-orange', cancelable: true, replaced: true },
    { value: 10, label: 'Stopped', color: '#CD3264', palette: 'system-pink', cancelable: false, replaced: false},
    { value: 11, label: 'Suspended', color: '#CD3264', palette: 'system-pink', cancelable: false, replaced: false},
    { value: 12, label: 'Pending New', color: '#E1C84B', palette: 'system-yellow', cancelable: true, replaced: true },
    { value: 13, label: 'Calculated', color: '#27A69A', palette: 'brand-cta', cancelable: true, replaced: true },
    { value: 14, label: 'Expired', color: '#CD3264', palette: 'system-pink', cancelable: false, replaced: false},
    { value: 15, label: 'Accepted For Bidding', color: '#27A69A', palette: 'brand-cta', cancelable: true, replaced: true },
    { value: 16, label: 'Pending Replace', color: '#E1C84B', palette: 'system-yellow', cancelable: true, replaced: true },
    { value: 17, label: 'Cancel Request', color: '#CD3264', palette: 'system-pink', cancelable: true, replaced: true },
    { value: 18, label: 'Cancel Replace Request', color: '#CD3264', palette: 'system-pink', cancelable: true, replaced: true },
    { value: 19, label: 'Pending Cancel Request', color: '#E1C84B', palette: 'system-yellow', cancelable: true, replaced: true },
    { value: 20, label: 'Pending Cancel Replace Request', color: '#E1C84B', palette: 'system-yellow', cancelable: true, replaced: true },
    { value: 21, label: 'Order Cancel Reject', color: '#CD3264', palette: 'system-pink', cancelable: false, replaced: false}
  ];
  static WORKING_STATUS = [
    { value: 1, label: 'New' },
    { value: 2, label: 'Pending' },
    { value: 6, label: 'Pending Cancel' },
    { value: 17, label: 'Cancel Request' },
    { value: 19, label: 'Pending Cancel Request'},
    { value: 9, label: 'Replaced'},
    { value: 0, label: 'Unknown'},
    { value: 7, label: 'Partially Filled' },
    { value: 12, label: 'PendingNew' },
    { value: 13, label: 'Calculated'},
    { value: 15, label: 'Accepted For Bidding' },
    { value: 16, label: 'Pending Replace' },
    { value: 18, label: 'Cancel Replace Request' },
    { value: 20, label: 'Pending Cancel Replace Request' },
  ];
  static FILLED_STATUS = [
    { value: 3, label: 'Completed' },
    { value: 10, label: 'Stopped' },
    { value: 11, label: 'Suspended' },
    { value: 8, label: 'Done For Day' }
  ];
  static CANCELLED_STATUS = [
    { value: 4, label: 'Cancelled' }
  ];
  static FAILED_STATUS = [
    { value: 5, label: 'Rejected' },
    { value: 14, label: 'Expired' },
    { value: 21, label: 'Order Cancel Reject'}
  ];
}

export enum CancelableStatus {
  Unknown,
  New,
  Pending,
  PartiallyFilled,
  DoneForDay,
  PendingNew,
  Calculated,
  PendingReplace
}

export enum FixTransactionStatus {
  'Unknown' = 0,
  'New' = 1,
  'Pending' = 2,
  'Completed' = 3,
  'Cancelled' = 4,
  'Rejected' = 5,
  'PendingCancel' = 6,
  'Replaced' = 9,
  'Expired' = 14,
  'CancelRequest' = 17,
  'PendingCancelRequest' = 19,
  'PendingCancelReplaceRequest' = 20
}
