import { Pipe, PipeTransform } from '@angular/core';
import { ParameterFieldTypes } from '../constants/flow-parameter';

@Pipe({
  name: 'flowParameterFieldType'
})
export class FlowParameterFieldTypePipe implements PipeTransform {
  transform(type: number): unknown {
    const fieldTypes = ParameterFieldTypes.TYPES_TABLE;
    const typeObj = fieldTypes.find(e => e.value === type);
    if (typeObj) {
      return typeObj.name;
    }
    return '-';
  }
}
