import { Injectable } from '@angular/core';
import { Storage } from '@constants/storage';
import { ApiUserDetails } from '@interfaces/model/auth.interface';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor() { }

  setVerifyData(data: string) {
    localStorage.setItem(Storage.VERIFY_DATA, JSON.stringify(data));
  }

  getVerifyData(): boolean {
    return !!localStorage.getItem(Storage.VERIFY_DATA);
  }

  removeVerifyData() {
    localStorage.removeItem(Storage.VERIFY_DATA);
  }

  setUserData(data: ApiUserDetails) {
    localStorage.setItem(Storage.USER_DATA, JSON.stringify(data));
  }

  getUserData(): ApiUserDetails {
    return JSON.parse(localStorage.getItem(Storage.USER_DATA)!);
  }

  setAccessToken(token: string) {
    localStorage.setItem(Storage.SESSION_TOKEN, token);
  }

  getAccessToken() {
    return localStorage.getItem(Storage.SESSION_TOKEN);
  }

  removeAccessToken() {
    localStorage.removeItem(Storage.SESSION_TOKEN);
  }

  clear() {
    this.removeAccessToken();
  }

  isLoggedIn() {
    return !!this.getAccessToken();
  }

  logout() {
    this.clear();
  }
}
