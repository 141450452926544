import {Component} from '@angular/core';
import {ModalComponent} from '@components/common/modal/modal.component';

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss'],
})
export class DeleteModalComponent {

  public name = '';
  public type = '';

  constructor(
    protected readonly parent: ModalComponent
  ) {
    this.name = parent.data?.name || '';
    this.type = parent.data?.type || '';
  }

  public close(value: boolean): void {
    this.parent.close(value);
  }

}
