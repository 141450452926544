import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'serviceHealth'
})
export class DashboardServiceHealthPipe implements PipeTransform {

  transform(status: boolean): string {
    return status ? 'Running' : 'Stopped';
  }
}
