import { Component,Input,Output, OnInit,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit {

  @Input() successMessage = '';
  @Output() clearMessage = new EventEmitter<boolean>();
  showMessage = true;

  constructor() { }

  ngOnInit() {
    if(this.successMessage){
      setTimeout(()=>{
        this.showMessage = false;
        this.clearMessage.emit(true);
      },2000);
    }
  }

}
