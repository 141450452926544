import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Directive,
  Input,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { ITableRow, ITableRowColumn } from '@interfaces/common/table.interface';

export interface ICtx {
  row: ITableRow;
  column: ITableRowColumn;
}

@Component({
  selector: 'app-table-column',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TableColumnComponent {
  @Input()
  public key!: string;

  @ContentChild(TemplateRef)
  public columnTemplate!: TemplateRef<unknown>;

  @Input()
  public columnData!: ITableRowColumn;

  @Input()
  public rowData!: ITableRow;

  public get ctx(): ICtx {
    return {
      row: this.rowData,
      column: this.columnData,
    };
  }
}
