/* eslint-disable max-len */
export type DeviceType = 'mobile' | 'tablet' | 'desktop' | 'web-mobile' | 'web-tablet' | 'native';

export type NavigationPage = 'account' | 'auth' | 'home' | 'notifications' | 'trade' | 'trade/confirmation' | 'transactions' | 'watchlist' |
                             'auth/invite' | 'auth/password-forgot' | 'auth/password-reset' | 'auth/password-reset-confirm' |
                             'auth/password-set' | 'auth/sign-in' | 'auth/password-sign-in' | 'account/my-account' | 'account/manage-presets' |
                             'account/request-instrument' | 'account/feedback' | 'account/help' | 'account/edit-preset' | string;
export type NavigationDirection = 'forward' | 'backward' | 'root';

export type IconSize = 'xs' | 'micro' | 'pico' | 'tiny' | 'small' | 'medium' | 'large' | 'xl' | 'huge' | 'mhuge' | 'xhuge' | 'input' | string;
export type IconColor = 'brand-dark' | 'brand-light' | 'brand-cta' | 'brand-tint' | 'brand-dark-tint' | 'brand-light-tint' | 'brand-grey' |
  'brand-highlight' | 'system-green' | 'system-yellow' | 'system-pink' | 'system-blue' | 'system-orange' | string;

export type IconImage = 'alert-error' | 'alert-information' | 'alert-success' | 'alert-warning' |
  'aspirations-graph-petal-fill' | 'aspirations-graph-petal-outline' | 'avatar-company' | 'avatar-individual' |
  'checkbox-active' | 'checkbox-inactive' | 'experience-education' | 'experience-other' | 'experience-volunteering' |
  'experience-work' | 'message-attach' | 'message-cover-link' | 'message-file' | 'message-heading' | 'message-image-search' |
  'message-link' | 'message-list-bullet' | 'message-list-number' | 'message-mention' | 'message-picture' | 'message-quote-marks' |
  'message-section-break' | 'message-send' | 'message-share' | 'message-strikethrough' | 'message-text' | 'message-text-align-center' |
  'message-text-align-justify' | 'message-text-align-left' | 'message-text-align-right' | 'message-text-bold' | 'message-text-italic' |
  'message-text-underline' | 'message-trash' | 'message-video' | 'navigation-chat' | 'navigation-chat-alert' | 'navigation-community' |
  'navigation-company-profile' | 'navigation-company-profile-alert' | 'navigation-explore' | 'navigation-profile' |
  'navigation-profile-alert' | 'navigation-saved' | 'navigation-saved-alert' | 'profile-about-us' |
  'profile-activity' | 'profile-billing' | 'profile-contact' | 'profile-delete-account' | 'profile-help' | 'profile-log-out' |
  'profile-notifications' | 'profile-password' | 'profile-privacy' | 'profile-reports' | 'profile-sign-in' | 'profile-troubleshooting' |
  'radio-button-active' | 'radio-button-inactive' | 'social-email' | 'social-facebook' | 'social-instagram' | 'social-linkedin' | 'social-messenger' |
  'social-sms' | 'social-twitter' | 'social-whatsapp' | 'social-youtube' | 'status-badge-attention-seeker' | 'status-badge-certifier' |
  'status-badge-edit' | 'status-badge-influencer' | 'status-badge-unclaimed' | 'status-badge-verified' | 'status-badge-visionary' |
  'subscribe-base' | 'subscribe-previously-subscribed' | 'subscribe-subscribed' | 'system-add' | 'system-arrow-down' | 'system-arrow-left' |
  'system-arrow-right' | 'system-arrow-up' | 'system-bookmark' | 'system-calendar' | 'system-card-view' | 'system-close' |
  'system-contract' | 'system-copy' | 'system-dislike' | 'system-down' | 'system-download' | 'system-edit' | 'system-email' |
  'system-expand' | 'system-external-link' | 'system-faceid' | 'system-flag' | 'system-full-screen' | 'system-full-screen-exit' | 'system-handle' |
  'system-info' | 'system-left' | 'system-like' | 'system-location-pin' | 'system-lock' | 'system-map' | 'system-menu' |
  'system-minus' | 'system-more-horizontal' | 'system-more-vertical' | 'system-move' | 'system-notifications' |
  'system-notifications-alert' | 'system-phone' | 'system-plus' | 'system-posts' | 'system-recently-seen' |
  'system-record' | 'system-redo' | 'system-refresh' | 'system-right' | 'system-save' | 'system-search' |
  'system-settings' | 'system-sort' | 'system-star' | 'system-switch' | 'system-tick' | 'system-time' | 'system-touchid' | 'system-trade' | 'system-transactions' |
  'system-trash' | 'system-trending' | 'system-undo' | 'system-unlock' | 'system-up' | 'system-upload' | 'system-video' |
  'system-visibility-off' | 'system-visibility-on' | 'system-watchlist' | 'system-web' | 'value-aspirations' | 'value-conflicting' |
  'value-core' | 'value-new' | 'value-parent' | 'value-proposed' | 'value-search' | 'value-subvalue' |
  'value-category-economic-button' | 'value-category-economic-icon' | 'value-category-environmental-button' |
  'value-category-environmental-icon' | 'value-category-integrity-button' | 'value-category-integrity-icon' |
  'value-category-social-button' | 'value-category-social-icon' | 'value-category-wellbeing-button' |
  'value-category-wellbeing-icon' | 'edit' | string;

export interface IconItem {
  id: string;
  category: IconCategory;
}

export enum IconCategory {
  ALERT = 'Alert',
  ASPIRATIONS_GRAPH = 'Aspirations Graph',
  AVATAR = 'Avatar',
  CHECKBOX = 'Checkbox',
  EXPERIENCE = 'Experience',
  MESSAGE = 'Message',
  NAVIGATION = 'Navigation',
  PROFILE = 'Profile',
  RADIO_BUTTON = 'Radio Button',
  SOCIAL = 'Social',
  STATUS_BADGES = 'Status Badges',
  SUBSCRIBE = 'Subscribe',
  SYSTEM = 'System',
  VALUE = 'Value',
  VALUE_CATEGORY = 'Value Category'
}

// Modal

export type ModalOverlayStyle = 'default' | 'dark' | string;

export enum ModalSize {
  DYNAMIC = 'dynamic',
  CONFIRM = 'confirm',
  BASE = 'base',
  SMALL = 'small',
  MEDIUM = 'medium',
  DEFAULT = 'default',
  LARGE = 'large',
  XLARGE = 'xlarge',
  FULLSCREEN = 'fullScreen'
}

// Password conditions {

  export interface PasswordConditions {
  uppercase: boolean;
  lowercase: boolean;
  numeric: boolean;
  length: boolean;
  }

// Api Service

export type HttpMethods = 'get' | 'post' | 'patch' | 'upload' | 'delete' | 'put' | 'multipart' | 'download' | string;
