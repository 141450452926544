/* eslint-disable max-len */
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output, SimpleChanges,
  ViewChild
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators, ValidatorFn} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {dropdownAnimation} from '@animations/dropdown.animations';
import {ValueChangeEvent} from '@interfaces/common/form.interface';


@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  animations: [dropdownAnimation()],
  styleUrls: ['./input.component.scss']
})

/**
 * @name InputComponent
 * @description Functional form component for Input Text, Password, Emails etc.
 * @property label<string> - The floating label text of the input field
 * @property name<string> - Passed as a name tag to the form element
 * @property toolTip<string> - Text to display when hovering over the lock icon. Works only if locked = true
 * @property tabIndex<number | 'auto'> - Determines the element's tab index
 * @property noTab<boolean> - Determines if the element is included in the tab order
 * @property hasFocus<boolean> - If set to true, it initializes with having focus. Use only one per page
 * @property pattern<string> - RegExp pattern for value validation
 * @property errorLabel<string> - External error label (from server events)
 * @property type<string> - The element's content type. Determines behavior and validation
 * @property disabled<boolean> - If set to true, the element is read-only
 * @property validateOn<'blur' | 'change'> - Determines if content validation will happen while typing or when focus is lost.
 * @property info<string> - The text content of the information line below the element
 * @property locked<boolean> - If true the element is read only and a lock icon appears with a tooltip on hover.
 * @property required<boolean> - If true the element will be marked as invalid if not filled
 * @property maxlength<number> - The maximum characters allowed to be typed in the element
 * @property verifying<boolean> - Toggles the verifying icon animation, useful for server queries
 * @property error<boolean> - If true forces a custom error display. Works in conjunction with errorLabel
 * @property value<string> - Sets a predefined value for the element
 * @emits valueCleared<{value:string,status:string}> - Fires whenever the user clears the element value
 * @emits valueChanged<{value:string,status:string}> - Fires whenever the element value is changed
 * @emits lostFocus<{value:string,status:string}> - Fires whenever the element loses focus
 * @implements OnInit, OnDestroy
 * @author Sotiris Varotsis
 */

export class InputComponent implements OnInit, OnDestroy, OnChanges {

  @ViewChild('input', {static: true}) input!: ElementRef;
  @Output() valueCleared: EventEmitter<any> = new EventEmitter<any>();
  @Output() valueChanged: EventEmitter<ValueChangeEvent> = new EventEmitter<ValueChangeEvent>();
  @Output() lostFocus: EventEmitter<ValueChangeEvent> = new EventEmitter<ValueChangeEvent>();

  @Input() label = '';
  @Input() placeholder = '';
  @Input() name = '';
  @Input() toolTip = 'Cannot change content';
  @Input() tabIndex: any = 'auto';
  @Input() noTab = false;
  @Input() hasFocus = false;
  @Input() pattern: any = '';
  @Input() type: 'text' | 'password' | 'email' | 'number' | 'tel' = 'text';
  @Input() inputMode: 'numeric' | 'text' | 'decimal' | 'email' = 'text';
  @Input() minNumber = 0;
  @Input() validateOn: 'blur' | 'change' = 'change';
  @Input() info = '';
  @Input() locked = false;
  @Input() required = false;
  @Input() requiredLabel = false;
  @Input() maxlength = 524288;

  lvDisabled = false;
  get disabled(): boolean {
    return this.lvDisabled;
  }

  @Input() set disabled(value: boolean) {
    this.lvDisabled = value;
    if (value) {
      this.formStates.disabled = true;
      this.tabIndex = -1;
    } else {
      this.formStates.disabled = false;
      this.tabIndex = 'auto';
    }
  }

  lvVerifying = false;

  get verifying(): boolean {
    return this.lvVerifying;
  }

  @Input() set verifying(value: boolean) {
    this.lvVerifying = value;
    this.handleIcon();
  }

  lvErrorLabel = '';

  get errorLabel(): string {
    return this.lvErrorLabel;
  }

  @Input() set errorLabel(value: string) {
    this.lvErrorLabel = value;
    if (value) {
      this.errorMessage = value;
    }
  }

  lvError = false;

  get error(): boolean {
    return this.lvError;
  }

  @Input() set error(value: boolean) {
    this.lvError = value;
    this.formStates.error = value;
    if (value) {
      this.form.setErrors({incorrect: true});
      this.form.markAsTouched();
    } else {
      this.form.setErrors(null);
    }
  }

  lvValue: string | number | null | undefined = null;
  get value(): string | number | null | undefined {
    return this.lvValue;
  }

  @Input() set value(value: string | number | null | undefined) {
    this.lvValue = value;
    if (this.form.controls['formValue']) {
      this.form.controls['formValue'].setValue(this.value);
      if (value) {
        this.form.controls['formValue'].markAsDirty();

        // Update the formStates in case the value comes asynchronously
        // and not during the component initialization.
        if (!this.formStates.labelRaised) {
          this.formStates.labelRaised = true;
        }
        if (!this.formStates.valid) {
          this.formStates.valid = true;
        }
      } else {
        this.form.controls['formValue'].markAsPristine();
        this.formStates.labelRaised = false;
      }
    }
  }

  /**
   * Use formStates to programmatically control the form element's state
   * These are used as CSS class definitions as well.
   * Copy them over to other form elements
   *
   * @property disabled - When the element is disabled
   * @property labelRaised - When the floating label needs to be raised
   * @property focused - When the element has focused
   * @property error - When the element has an error
   * @property valid - When the value of the element is valid
   * @property selected - Used for radio and checkboxes
   */

  formStates = {
    disabled: false,
    labelRaised: false,
    focused: false,
    error: false,
    valid: false,
    selected: false
  };

  /**
   * displayIcon is only used for input fields that have additional icons
   * such as clear, validation, password reveal and spinners
   */

  displayIcon = '';

  passwordVisible = false;
  errorMessage = '';

  form: FormGroup = new FormGroup({});
  formSubscription: Subscription = new Subscription();
  statusSubscription: Subscription = new Subscription();

  formValidators: ValidatorFn[] = [];

  valueClearedObservable = this.valueChanged.asObservable();
  valueChangedObservable = this.valueChanged.asObservable();
  lostFocusChangedObservable = this.valueChanged.asObservable();

  constructor(private fb: FormBuilder, public translate: TranslateService) {
  }

  ngOnInit(): void {
    if (this.required) {
      this.formValidators.push(Validators.required);
    }
    if (this.type === 'email') {
      this.formValidators.push(Validators.email);
    }

    const validators = this.formValidators;
    this.form = this.fb.group({
      formValue: new FormControl('',{validators, updateOn: (this.validateOn === 'change' ? 'change' : 'blur')})
    });

    if (this.value) {
      this.form.controls['formValue'].setValue(this.value);
      this.form.controls['formValue'].markAsDirty();
      this.formStates.labelRaised = true;
      this.formStates.valid = true;
    } else {
      this.form.controls['formValue'].markAsPristine();
    }

    if (this.locked) {
      this.disabled = true;
      this.formStates.disabled = true;
    }

    if (this.disabled) {
      this.formStates.disabled = true;
      this.tabIndex = -1;
    }

    if (this.hasFocus) {
      this.input.nativeElement.focus();
    }

    this.handleIcon();
    this.formSubscription = this.form.valueChanges.pipe(debounceTime(300),distinctUntilChanged()).subscribe(() => {
      // this.errorMessage = this.errorLabel || this.translate.instant('COMPONENTS.INPUT.ERROR');
      this.formStates.error = false;
      if (this.form.controls['formValue'].errors?.['email']) {
        this.formStates.error = true;
        this.errorMessage = this.errorLabel || this.translate.instant('COMPONENTS.INPUT.ERROR_INVALID_EMAIL');
      }
      if (this.form.controls['formValue'].errors?.['required']) {
        this.formStates.error = true;
        this.errorMessage = this.errorLabel || this.translate.instant('COMPONENTS.INPUT.ERROR_REQUIRED');
      }
      if (!this.form.controls['formValue'].value && !this.required) {
        this.form.controls['formValue'].markAsPristine();
      }
      this.handleIcon();
      this.valueChanged.emit({value: this.form.controls['formValue'].value, status: this.form.controls['formValue'].status, error: this.form.controls['formValue'].errors});
    });

    this.statusSubscription = this.form.statusChanges.subscribe(status => {
      this.formStates.valid = status === 'VALID';
      this.handleIcon();
    });
  }

  disableTab(evt: any): void {
    evt.preventDefault();
  }

  togglePasswordVisibility(e: Event): void {
    e.preventDefault();
    e.stopPropagation();
    this.passwordVisible = !this.passwordVisible;
  }

  clear(e: Event): void {
    e.preventDefault();
    e.stopPropagation();
    this.input.nativeElement.value = '';
    this.form.controls['formValue'].setValue('');
    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.handleIcon();
    this.valueCleared.emit();
  }

  /**
   * getFormClasses()
   *
   * @description constructs the form class object based on the element's states
   * @returns string
   */

  getFormClasses(): string {
    const classArray = [];
    for (const [key, value] of Object.entries(this.formStates)) {
      if (value) {
        classArray.push(key);
      }
    }
    return classArray.join(' ');
  }

  handleIcon(): void {
    if (this.verifying) {
      this.displayIcon = '';
    } else {
      if (this.type === 'password') {
        this.displayIcon = 'eye';
      } else {
        if (this.locked) {
          this.displayIcon = 'lock';
        } else {
          if (this.formStates.focused) {
            if (this.form.controls['formValue'].value) {
              this.displayIcon = 'clear';
            } else {
              this.displayIcon = '';
            }
          } else {
            if (this.formStates.valid) {
              this.displayIcon = 'tick';
            } else {
              this.displayIcon = '';
            }
          }
        }
      }
    }
  }

  /**
   * @name onFocus()
   * Handles events when the element gains focus. Copy this over to your form component.
   * labelRaised and focused formStates are necessary. The rest pertains to the icons
   */

  onFocus(): void {
    this.formStates.labelRaised = true;
    this.formStates.focused = true;
    if (this.type !== 'password') {
      if (this.form.controls['formValue'].value) {
        this.displayIcon = 'clear';
      } else {
        this.displayIcon = '';
      }
    } else {
      this.displayIcon = 'eye';
    }
  }

  /**
   * @name onBlur()
   * Handles events when the element loses focus. Copy this over to your form component.
   * labelRaised and focused formStates are necessary as well as the lostFocus emitted event. The rest pertains to the icons
   */

  onBlur(): void {
    const value = this.form.controls['formValue'].value;
    this.lostFocus.emit({value: this.form.controls['formValue'].value, status: this.form.controls['formValue'].status});
    if (!value) {
      this.formStates.labelRaised = false;
    }
    this.formStates.focused = false;
    if (this.type !== 'password') {
      if (this.formStates.valid && value) {
        this.displayIcon = 'tick';
      } else {
        this.displayIcon = '';
      }
    } else {
      this.displayIcon = 'eye';
    }

    this.formStates.error = false;
    if (this.form.controls['formValue'].errors?.['email']) {
      this.formStates.error = true;
      this.errorMessage = this.errorLabel || this.translate.instant('COMPONENTS.INPUT.ERROR_INVALID_EMAIL');
    }
    if (this.form.controls['formValue'].errors?.['required']) {
      this.formStates.error = true;
      this.errorMessage = this.errorLabel || this.translate.instant('COMPONENTS.INPUT.ERROR_REQUIRED');
    }
    if (!this.form.controls['formValue'].value && !this.required) {
      this.form.controls['formValue'].markAsPristine();
    }
    this.handleIcon();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['hasFocus'] && changes['hasFocus'].currentValue !== changes['hasFocus'].previousValue) {
      if (changes['hasFocus'].currentValue === true) {
        this.input.nativeElement.focus();
      } else {
        this.input.nativeElement.blur();
      }
    }
  }

  /**
   * @name ngOnDestroy()
   * Make sure to always unsubscribe your RxJs subscriptions otherwise
   * bad things will happen
   */

  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
    if (this.statusSubscription) {
      this.statusSubscription.unsubscribe();
    }
  }

}
