import {Component} from '@angular/core';
import {ModalComponent} from '@components/common/modal/modal.component';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class ConfirmModalComponent {

  public name = '';
  public type = '';
  public targetEnvironment = '';

  constructor(
    protected readonly parent: ModalComponent
  ) {
    this.name = parent.data?.name || '';
    this.type = parent.data?.type || '';
    this.targetEnvironment = parent.data?.targetEnvironment || '';
  }

  public close(value: boolean): void {
    this.parent.close(value);
  }

}
