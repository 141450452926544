import { Pipe, PipeTransform } from '@angular/core';
import { BrokerSideTypes } from '@constants/broker-sides';

@Pipe({
  name: 'brokerSide'
})
export class BrokerSidePipe implements PipeTransform {

  transform(side: any): string {
    const brokerSides = BrokerSideTypes.TYPES_TABLE;
    const sideFound = brokerSides.find(e => e.value === side);
    if (sideFound) {
      return sideFound.name;
    }
    return '-';
  }

}


