import {Component, Input, OnInit} from '@angular/core';
import {IconColor, IconImage, IconSize} from '@interfaces/common/common.interface';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

  @Input() leftIcon: IconImage = '';
  @Input() iconSize: IconSize = 'small';
  @Input() rightIcon: IconImage = '';
  @Input() type: 'primary' | 'secondary' | 'negative' | 'flat' | 'icon' | 'error' | 'link' = 'primary';
  @Input() linkColor: 'cta' | 'light' = 'cta';
  @Input() size: 'tiny' | 'small' | 'medium' | 'large' | 'round' = 'medium';
  @Input() disabled = false;
  @Input() loading = false;

  @Input() iconColor: IconColor = 'brand-light';
  loaderColor: 'light' | 'cta' = 'light';

  constructor() { }

  ngOnInit(): void {
    if (this.type === 'secondary') {
      this.loaderColor = 'cta';
    }
  }

}
