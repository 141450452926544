import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TabBarItem} from '@interfaces/common/tab-bar.interface';

@Component({
  selector: 'app-tab-bar',
  templateUrl: './tab-bar.component.html',
  styleUrls: ['./tab-bar.component.scss'],
})
export class TabBarComponent implements OnInit {
  @Input() items!: Array<TabBarItem>;
  @Input() device!: string;
  @Output() tabClick = new EventEmitter<TabBarItem>();

  constructor() {
  }

  ngOnInit() {
  }

  itemClicked(selectedItem: TabBarItem): void {
    this.tabClick.emit(selectedItem);
    for (const item of this.items) {
      item.active = item.id === selectedItem.id;
    }
  }

}
