import {
  ChangeDetectionStrategy,
  Component, ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  Output, QueryList,
  ViewEncapsulation
} from '@angular/core';
import { ITableActionOutput, ITableColumn, ITableRow, ITableSortOutput } from '@interfaces/common/table.interface';
import {TableColumnComponent} from '@components/common/table/table-column/table-column.component';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TableComponent implements OnChanges {
  @Output()
  public rowClicked: EventEmitter<ITableRow> = new EventEmitter<ITableRow>();

  @Output()
  public actionClicked: EventEmitter<ITableActionOutput> = new EventEmitter<ITableActionOutput>();

  @Output()
  public sorted: EventEmitter<ITableSortOutput> = new EventEmitter<ITableSortOutput>();

  @Input()
  public columns: ITableColumn[] = [];

  @Input()
  public rows?: ITableRow[] = [];

  @Input()
  public sortKey = '';

  @Input() rowReadMode = false;

  @Input()
  public sortDirection: 'desc' | 'asc' = 'desc';

  @ContentChildren(TableColumnComponent)
  public set columnTemplates(columnTemplates: QueryList<TableColumnComponent>) {
    // eslint-disable-next-line no-underscore-dangle
    this._columnTemplates = columnTemplates;
    columnTemplates.forEach((e) => {
      // eslint-disable-next-line no-underscore-dangle
      this._columnTemplatesObject[e.key] = e;
    });
  }

  public get columnTemplates(): QueryList<TableColumnComponent> {
    // eslint-disable-next-line no-underscore-dangle
    return this._columnTemplates;
  }

  public get columnTemplatesObject(): { [key: string]: TableColumnComponent } {
    // eslint-disable-next-line no-underscore-dangle
    return this._columnTemplatesObject;
  }

  protected _columnTemplates!: QueryList<TableColumnComponent>;

  protected _columnTemplatesObject: { [key: string]: TableColumnComponent } = {};

  public ngOnChanges(): void {
    // this.loadPage();
  }

  public headerClick(column: ITableColumn): void {
    if (column.sortable) {
      if (this.sortKey === column.key) {
        if (this.sortDirection === 'desc') {
          this.sortDirection = 'asc';
        } else {
          this.sortKey = '';
          this.sortDirection = 'desc';
        }
      } else {
        this.sortDirection = 'desc';
        this.sortKey = column.key;
      }
      this.sorted.emit({
        sortKey: this.sortKey,
        sortDirection: this.sortDirection
      });
    }
  }

  public rowClick(row: ITableRow, column: ITableColumn): void {
    if (column.isAction) {
      this.actionClicked.emit({
        data: row,
        action: column.key
      });
    } else {
      this.rowClicked.emit(row);
    }
  }

}
