import { Pipe, PipeTransform } from '@angular/core';
import { TransactionStatus } from '../constants/transaction-status';

@Pipe({
  name: 'transactionStatusColor'
})
export class TransactionStatusColorPipe implements PipeTransform {

  transform(transactionStatus: any, palette = false): string {
    const status = TransactionStatus.ALL_STATUS.find(e => e.value === transactionStatus);
    if (status) {
      return palette ? status.palette : status.color;
    }
    return '-';
  }

}
