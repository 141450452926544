import { Pipe, PipeTransform } from '@angular/core';
import { TimeInForce } from '../constants/time-in-force';

@Pipe({
  name: 'timeInForce'
})
export class TimeInForcePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    const type = TimeInForce.TYPES_TABLE.find(tif => tif.value === value);
    return type?.name;
  }

}
