/* eslint-disable @typescript-eslint/naming-convention */
export class ParameterSymbols {
  static TYPES_TABLE = [
    { value: '=', name: '=', subTitle: 'equal' },
    { value: '!=', name: '!=', subTitle: 'notEqual' },
    { value: '<', name: '<', subTitle: 'less' },
    { value: '>', name: '>', subTitle: 'greater' },
    { value: '<=', name: '<=', subTitle: 'lessEqual' },
    { value: '>=', name: '>=', subTitle: 'greaterEqual' }
  ];
}
export class ParameterSymbolsBinary {
  static TYPES_TABLE = [
    { value: '=', name: '=', subTitle: 'equal' },
    { value: '!=', name: '!=', subTitle: 'notEqual' }
  ];
}

export class ParameterConditionTypes {
  static TYPES_TABLE = [
    { value: 'AND', name: 'AND' },
    { value: 'OR', name: 'OR' }
  ];
}

export class ParameterTargetTypes {
  static TYPES_TABLE = [
    { value: 1, name: 'Flow parameter' },
    { value: 2, name: 'Order type' },
    { value: 3, name: 'Time in force' },
    { value: 4, name: 'Side' }
  ];
}

export class ParameterFieldTypes {
  static TYPES_TABLE = [
    { value: 1, name: 'DateTime' },
    { value: 2, name: 'Number' },
    { value: 3, name: 'Dropdown' },
    { value: 4, name: 'Text' },
    { value: 5, name: 'Price' },
    { value: 6, name: 'PriceCents' },
    { value: 7, name: 'Decimal' },
  ];
}

export class ParameterValueTargetTypes {
  static TYPES_TABLE = [
    {value: 1, name: 'Flow parameter'},
    {value: 5, name: 'Order Quantity'},
    {value: 6, name: 'Raw Value'}
  ]
}

export class ParameterEnforcementTargetTypes {
  static TYPES_TABLE = [
    {value: 1, name: 'Flow parameter'},
    {value: 2, name: 'Order type'},
    {value: 3, name: 'Time in force'},
    {value: 4, name: 'Side'}
  ]
}
