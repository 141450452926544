/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DropDownOption, DropDownType, GroupedDropDownOption, ValueChangeEvent } from '@interfaces/common/form.interface';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { InputComponent } from '../input/input.component';

@Component({
  selector: 'app-single-selection',
  templateUrl: './single-selection.component.html',
  styleUrls: ['./single-selection.component.scss'],
})
export class SingleSelectionComponent extends InputComponent implements OnInit {

  @ViewChild('input', {static: true})
  inputElRef!: ElementRef;
  @ViewChild('wrapper')
  wrapperElRef!: ElementRef;

  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  @Input() override placeholder: string = 'Select options';
  @Input() optionsType: DropDownType = 'list';

  @Input() selectData = false;
  // @Input() idSelection = '';


  expanded = false;
  localSelectedValue!: DropDownOption;
  localSelectedValues: DropDownOption[] = [];

  options: DropDownOption[] = [];
  groupedOptions: GroupedDropDownOption[] = [];

  filteredOptions: DropDownOption[] = [];
  filteredGroupOptions: GroupedDropDownOption[] = [];


  @Output() selectionChange = new EventEmitter<any>();
  @Output() optionsToggled = new EventEmitter<any>();


  constructor(private cdr: ChangeDetectorRef,
    fb: FormBuilder, translate: TranslateService) {
    super(fb, translate);
  }

  private _searchable = false;

  get searchable(): boolean {
    return this._searchable;
  }

  @Input() set searchable(v: boolean) {
    this._searchable = v;
    if (v) {
      this.filteredOptions = [];
      this.filteredGroupOptions = [];
    }
  }

  private _items: DropDownOption[] = [];

  get items(): DropDownOption[] {
    return this._items;
  }

  @Input() set items(v: DropDownOption[]) {
    this._items = v;
    if (v) {
      this.options = v;
      if (this.selectedValue) {
        this.value = this.options.find((e) => e.value === this.selectedValue)?.name;
      }
      if (!this.searchable) {
        this.filteredOptions = this.options;
      }
    }
  }


  private _groupedItems: GroupedDropDownOption[] = [];

  get groupedItems(): GroupedDropDownOption[] {
    return this._groupedItems;
  }

  @Input() set groupedItems(v: GroupedDropDownOption[]) {
    this._groupedItems = v;
    if (v) {
      this.groupedOptions = v;
      if (this.selectedValue) {
        this.groupedOptions.forEach(group => {
          const selectedName = group.options.find((e) => e.value === this.selectedValue)?.name;
        });
      }
      if (!this.searchable) {
        this.filteredGroupOptions = this.groupedOptions;
      }

    }
  }
  get selectedValue(): any {
    return this.localSelectedValue;
  }

  @Input() set selectedValue(value: any) {
    this.localSelectedValue = value;
    if (value || typeof(value) === 'number') {
      if (this.optionsType !== 'groupList') {
        this.value = this.options.find((e) => e.value === value)?.name;
        this.options.forEach(e => {
          e.selected = e.value === this.localSelectedValue;
          return e;
        });
      } else {
        this.groupedOptions.forEach(group => {
          const selectedName = group.options.find((e) => e.value === value)?.name;
          if (selectedName) {
            this.value = selectedName;
            group.options.forEach(e => {
              e.selected = e.value === this.localSelectedValue;
              return e;
            });
            return;
          }
        });
      }
    } else {
      this.clear();
    }
  }

  get empty(): boolean {
    const isEmpty = this.optionsType !== 'groupList' ? this.filteredOptions.length <= 0 : this.filteredGroupOptions.length <= 0;
    return isEmpty;
  }


  @HostListener('document:click', ['$event.target'])
  public onClick(target: any): void {
    const wrapperRef = this.wrapperElRef?.nativeElement;

    if (wrapperRef && !wrapperRef.contains(target)) {
      this.expanded = false;
    }
  }


  @HostListener('document:mousedown', ['$event.target'])
  public onClick2(target: any): void {
   const wrapperRef = this.wrapperElRef?.nativeElement;

   if (wrapperRef && !wrapperRef.contains(target)) {
     this.expanded = false;
   }
  }

  override ngOnInit(): void {
    super.ngOnInit();
    if (this.searchable) {
      this.valueChangedObservable.pipe(distinctUntilChanged()).subscribe((event: ValueChangeEvent) => {
        if (this.optionsType !== 'groupList') {
          if (event.value) {
            this.formStates.focused = true;
            this.filteredOptions = this.options.filter(e => e.name.toLowerCase().indexOf(event.value.toLowerCase()) > -1);
            this.expanded = !this.empty;
          } else {
            this.formStates.error = false;
            this.filteredOptions = [...this.options];
          }
        } else {
          if (event.value) {
            this.formStates.focused = true;
            this.filteredGroupOptions = this.groupedOptions.map(item => {
              const newItem = {
                ...item,
                options: item.options.filter(e => e.name.toLowerCase().indexOf(event.value.toLowerCase()) > -1)
              };
             return newItem;
            });
            this.expanded = !this.empty;
          } else {
            this.formStates.error = false;
            this.filteredGroupOptions = [...this.groupedOptions];
          }
        }
      });
    }
  }

  exist(value: string): DropDownOption | undefined {
    return this.options.find(e => e.value === value);
  }

  toggle(event: Event): void {
    if (!this.disabled) {
      // event.preventDefault();
      // event.stopPropagation();
      this.expanded = !this.expanded && !this.empty;
      if (this.expanded) {
        this.inputElRef.nativeElement.focus();
        // this.onFocus();
        this.optionsToggled.emit(true);
      } else {
        this.inputElRef.nativeElement.blur();
        // this.onBlur();
      }
      this.cdr.detectChanges();

    }
  }

  select(option: DropDownOption): void {
    // this.localSelectedValue = option;
    this.selectedValue = option.value;
    if (this.optionsType !== 'groupList') {
    this.filteredOptions = [...this.options];

    } else {
      this.filteredGroupOptions = [...this.groupedOptions];
    }

    if (this.selectData) {
      this.selectionChange.emit(option.data);
    } else {
      this.selectionChange.emit(option.value);
    }
  }

  optionsChanged(options: DropDownOption[]): void {
    this.value = options.map(e => e.name).join(', ');
    if (!this.value) {
      this.formStates.labelRaised = false;
    }
  }

  override clear(): void {
    this.value = null;
    this.formStates.labelRaised = false;
  }

  save(options: DropDownOption[]): void {
    this.value = options.map(e => e.name).join(', ');
  }

  onClosed(): void {
    this.expanded = false;
  }
}
