import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { dropdownAnimation } from '@app/shared/animations/dropdown.animations';
import { IonPopover } from "@ionic/angular";
import { DateTime } from 'luxon';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
  animations: [dropdownAnimation()]
})
export class DateComponent implements OnInit {
  @Input() label: string | any = '';
  @Input() disabled = false;
  @Input() format: string | any = 'dd/MM/YY HH:mm';
  @Input() presentationType: string | 'date' | 'time' | 'date-time' = 'date-time';
  @Input() id: string | undefined;
  @Input() pickerSize: 'auto' | 'cover' = 'auto'; // 'auto' for desktop, 'cover' for mobile
  @Input() pickerstyle: 'popover' | 'modal' = 'popover';
  @Input() required = false;
  @Input() requiredLabel = false;
  @Input() nowButtonEnable = false;
  @Input() inModal = false;

  @Output() nowButtonDataChange = new EventEmitter<any>();
  @Output() dateChange = new EventEmitter<any>();

  @ViewChild(IonPopover) popover!: IonPopover;

  expanded = false;
  localValue: any = '';

  constructor() {
  }

  get value(): string | any {
    return this.localValue;
  }

  @Input() set value(value: string | any) {
    this.localValue = value;
  }

  onDateChange(event: any) {
    this.localValue = event.detail.value;
    this.expanded = false;
    if (this.presentationType !== 'date-time') {
      if (!this.inModal) {
        this.popover.dismiss();
      }
    }

    this.dateChange.emit(this.localValue);
  }

  onNowDateChange(event: any) {
    // const currentDate = new Date();
    // const formattedDate = currentDate.toLocaleString();
    // this.localValue = formattedDate;
    this.localValue = DateTime.now().toISO({ includeOffset: false }),
    this.nowButtonDataChange.emit(this.localValue);
    if (!this.inModal) {
      this.popover.dismiss();
    }
    this.expanded = false;
  }

  ngOnInit(): void {

  }

  showPicker() {
    if (this.inModal) {
      this.expanded = !this.expanded
    }
  }

}
