import {Component, Input, OnChanges, OnInit} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DropDownOption } from '@interfaces/common/form.interface';
import { TranslateService } from '@ngx-translate/core';
import { DropdownSelectionComponent } from '../dropdown-selection/dropdown-selection.component';

@Component({
  selector: 'app-search-selection',
  templateUrl: './search-selection.component.html',
  styleUrls: ['./search-selection.component.scss'],
})
export class SearchSelectionComponent extends DropdownSelectionComponent implements OnInit {
  @Input() override expanded = false;
  @Input() searchOnly = false;

  constructor(fb: FormBuilder, translate: TranslateService) {
    super(fb, translate);
   }

   override onBlur(): void {
    super.onBlur();
    if (!this.value && this.form.dirty) {
      this.formStates.error = true;
    }
  }

  override select(option: DropDownOption) {
    super.select(option);
    if (this.searchOnly) {
      this.clear();
    }
  }
}
