/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
import {Observable, Subject} from 'rxjs';

export class ModalRef {
  private _afterClosed = new Subject<any>();
  afterClosed: Observable<any> = this._afterClosed.asObservable();

  constructor() {
  }

  close(result?: any): void {
    this._afterClosed.next(result);
  }
}
