import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { InputComponent } from '../input/input.component';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ValueChangeEvent } from '@interfaces/common/form.interface';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent extends InputComponent implements OnInit, OnDestroy {

  @Input() rows = 7;
  @Input() columns = 30;
  @Input() minimumChars = -1;
  @Input() maximumChars = -1;
  charsTyped = 0;

  valueChangedSubscription: Subscription = new Subscription();

  constructor(
    fb: FormBuilder,
    translate: TranslateService) {
    super(fb, translate);
  }

  override ngOnInit(): void {
    if (this.minimumChars > -1) {
      this.formValidators.push(Validators.minLength(this.minimumChars));
    }
    if (this.maximumChars > -1) {
      this.formValidators.push(Validators.maxLength(this.maximumChars));
    }
    super.ngOnInit();

    if (this.value) {
      this.charsTyped = this.value.toString()?.length;
    }
    this.valueChangedSubscription = this.valueChangedObservable.pipe(distinctUntilChanged()).subscribe((e: ValueChangeEvent) => {
      this.charsTyped = e.value.length;
    });
  }

  override onBlur(): void {
    super.onBlur();
    if (this.form.controls['formValue'].errors?.['minlength']) {
      this.formStates.error = true;
      this.errorMessage = this.translate.instant('COMPONENTS.INPUT.ERROR_TOO_FEW_CHARS', { value: this.minimumChars });
    }
    if (this.form.controls['formValue'].errors?.['maxlength']) {
      this.formStates.error = true;
      this.errorMessage = this.translate.instant('COMPONENTS.INPUT.ERROR_TOO_MANY_CHARS', { value: this.maximumChars });
    }
  }

  override ngOnDestroy(): void {
    if (this.valueChangedSubscription) {
      this.valueChangedSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }
}
