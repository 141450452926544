import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'noValue'
})
export class NoValuePipe implements PipeTransform {

  transform(value: unknown): any {
    return value || typeof(value) === 'number' ? value: '---';
  }

}
