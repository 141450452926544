/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DropDownOption, DropDownType, ValueChangeEvent } from '@interfaces/common/form.interface';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { InputComponent } from '../input/input.component';

@Component({
  selector: 'app-dropdown-selection',
  templateUrl: './dropdown-selection.component.html',
  styleUrls: ['./dropdown-selection.component.scss'],
})
export class DropdownSelectionComponent extends InputComponent implements OnInit {
  @Input() maximum = -1;
  @Input() minimum = 2;
  @Input() selectionType: DropDownType = 'list';
  @Output() selectionChange = new EventEmitter<any>();

  expanded = false;
  localSelectedValue!: DropDownOption;
  localSelectedValues: DropDownOption[] = [];

  options: DropDownOption[] = [];
  filteredOptions: DropDownOption[] = [];



  constructor(
    fb: FormBuilder,
    translate: TranslateService) {
    super(fb, translate);
  }

  private _searchable = false;

  get searchable(): boolean {
    return this._searchable;
  }

  @Input() set searchable(v: boolean) {
    this._searchable = v;
    if (v) {
      this.filteredOptions = [];
    }
  }

  private _items: DropDownOption[] = [];

  get items(): DropDownOption[] {
    return this._items;
  }

  @Input() set items(v: DropDownOption[]) {
    this._items = v;
    this.filteredOptions = v;
    if (v) {
      this.options = v.map(item => {
        const newItem = {
          ...item,
          name: this.translate.instant(item.name)
        };
        return Object.assign({}, newItem);
      });
      if (!this.searchable) {
        this.filteredOptions = this.options;
      }
    }

  }

  get selectedValue(): any {
    return this.localSelectedValue;
  }

  @Input() set selectedValue(value: any) {
    this.localSelectedValue = value;
    if (value) {
      if (this.selectionType === 'list') {
        this.value = this.options.find((e) => e.value === value)?.name;
        this.options.forEach(e => {
          e.selected = e.value === this.localSelectedValue;
          return e;
        });
      }
      if (this.selectionType === 'checkbox') {
        this.value = this.options.map(e => e.name).join(', ');
      }
    }
  }

  get empty(): boolean {
    return this.filteredOptions.length <= 0;
  }

  override ngOnInit(): void {
    super.ngOnInit();
    if (this.searchable) {
      this.valueChangedObservable.pipe(distinctUntilChanged()).subscribe((event: ValueChangeEvent) => {
        if (event.value) {
          this.formStates.focused = true;
          this.filteredOptions = this.options.filter(e => e.name.toLowerCase().indexOf(event.value.toLowerCase()) > -1);
          this.expanded = !this.empty;
        } else {
          this.formStates.error = false;
          this.filteredOptions = [...this.options];
        }
      });
    }
  }

  exist(value: string): DropDownOption | undefined {
    return this.options.find(e => e.value === value);
  }

  toggle(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.expanded = !this.expanded && !this.empty;
  }

  select(option: DropDownOption): void {
    if (this.selectionType === 'list') {
      this.localSelectedValue = option;
      this.selectedValue = option.value;
      this.filteredOptions = [...this.options];
    }
    if (this.selectionType === 'checkbox') {
      this.localSelectedValues.push(option);
      this.selectedValue = this.localSelectedValues.map(e => e.value).join(', ');
    }

    this.selectionChange.emit(option.value);
  }

  optionsChanged(options: DropDownOption[]): void {
    this.value = options.map(e => e.name).join(', ');
    if (!this.value) {
      this.formStates.labelRaised = false;
    }
  }

  // add(option: DropDownOption): void {
  //   this.localSelectedValue = option;
  //   this.selectedValue = option.value;
  //   this.selectionChange.emit(option.value);
  // }

  override clear(): void {
    this.value = null;
    this.formStates.labelRaised = false;
  }

  save(options: DropDownOption[]): void {
    this.value = options.map(e => e.name).join(', ');
  }

  onClosed(): void {
    this.expanded = false;
  }
}
