import { Component } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(private translate: TranslateService) {
    this.setDefaultTranslation();
  }

  private async setDefaultTranslation() {
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }
}
