import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
})
export class SwitchComponent implements OnInit {

  @Input() checked = false;
  @Input() disabled = false;

  @Input() label: string | undefined;
  @Input() description: string | undefined;

  @Output() valueChange = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  toggle(): void {
    this.checked = !this.checked;
    this.valueChange.emit(this.checked);
  }
}
