/* eslint-disable @typescript-eslint/naming-convention */
export class BrokerSideTypes {
  static TYPES_TABLE = [
    { value: '1', name: 'Buy', color: 'positive' },
    { value: '2', name: 'Sell', color: 'negative' },
    { value: '3', name: 'Buy minus', color: 'positive' },
    { value: '4', name: 'Sell plus', color: 'negative' },
    { value: '5', name: 'Sell short', color: 'negative' },
    { value: '6', name: 'Sell short exempt', color: 'negative' },
    { value: '7', name: 'Undisclosed', color: 'neutral' },
    { value: '8', name: 'Cross', color: 'neutral' },
    { value: '9', name: 'Cross short', color: 'neutral' },
    { value: 'A', name: 'Cross short exempt', color: 'neutral' },
    { value: 'B', name: '"As Defined"', color: 'neutral' },
    { value: 'C', name: '"Opposite"', color: 'neutral' },
    { value: 'D', name: 'Subscribe', color: 'neutral' },
    { value: 'E', name: 'Redeem', color: 'neutral' },
    { value: 'F', name: 'Lend', color: 'neutral' },
    { value: 'G', name: 'Borrow', color: 'neutral' },
  ];
}
