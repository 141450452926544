import { enableProdMode } from '@angular/core';

// import { routes } from './app/app.routes';
import { environment } from './environments/environment';
import { AppModule } from './app/app.module';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

if (environment.production) {
  enableProdMode();
}


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));

  // bootstrapApplication(AppComponent, {
  //   providers: [
  //     { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  //     importProvidersFrom(IonicModule.forRoot({})),
  //     provideRouter(routes),
  //   ],
  // });
