/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, ComponentRef, OnDestroy, Type, ViewChild } from '@angular/core';
import { ModalInsertionDirective } from '@directives/modal-insertion.directive';
import { ModalOverlayStyle, ModalSize } from '@interfaces/common/common.interface';
import { Subject } from 'rxjs';
import { modalAnimation } from './modal-animation';
import { ModalRef } from './modal-ref';
import { ModalConfig } from './modal.config';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  animations: [modalAnimation()]
})
export class ModalComponent implements AfterViewInit, OnDestroy {

  componentRef!: ComponentRef<any>;

  @ViewChild(ModalInsertionDirective)
  insertionPoint!: ModalInsertionDirective;

  size: ModalSize = ModalSize.DEFAULT;
  data: any;
  style: ModalOverlayStyle = 'default';
  childComponentType!: Type<any>;
  private readonly _onClose = new Subject<any>();
  public onClose = this._onClose.asObservable();

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private cd: ChangeDetectorRef,
    private modalRef: ModalRef,
    public modalConfig: ModalConfig) {
      this.style = this.modalConfig.style || 'default';
      this.data = this.modalConfig.data;
  }

  ngAfterViewInit(): void {
    this.loadChildComponent(this.childComponentType);
    this.cd.detectChanges();

    window.addEventListener('keydown', (e: KeyboardEvent) => {
      if (e.keyCode === 27) {
        this.onOverlayClicked();
      }
    });
  }

  onOverlayClicked(): void {
    this.modalRef.close();
  }

  onDialogClicked(evt: MouseEvent): void {
    evt.stopPropagation();
  }

  loadChildComponent(componentType: Type<any>): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);

    const viewContainerRef = this.insertionPoint.viewContainerRef;
    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent(componentFactory);
  }

  ngOnDestroy(): void {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  close<T>(val?: T): void {
    this.modalRef.close(val);
    this._onClose.next(val);
  }
}
