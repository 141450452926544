import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
  @Input() pageIndex: number = 1;
  @Input() itemsPerPage = 10;
  @Input() total!: number;
  @Output() selectedPage: EventEmitter<number> = new EventEmitter<number>();


  public get pageCount(): number {
    // eslint-disable-next-line radix
    return parseInt((this.total / this.itemsPerPage).toString().split('.')[0]) + (this.total % this.itemsPerPage > 0 ? 1 : 0);
  }

  public get canPrevious(): boolean {
    return this.pageIndex > 1;
  }

  public get canNext(): boolean {
    return this.pageIndex < this.pageCount;
  }

  constructor() { }

  ngOnInit() {}

  public previous(): void {
    if (this.canPrevious) {
      this.pageIndex -= 1;
      this.selectedPage.emit(this.pageIndex);
      // this.loadPage();
    }
  }

  public next(): void {
    if (this.canNext) {
      this.pageIndex += 1;
      this.selectedPage.emit(this.pageIndex);
      // this.loadPage();
    }
  }

  // public loadPage(): void {
  //   const start = (this.pageIndex - 1) * this.itemsPerPage;
  //   const end = start + this.itemsPerPage;
  //   // this.renderRows = this.rows.filter((e, i) => i > start && i < end);
  // }



}
