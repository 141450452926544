/* eslint-disable @typescript-eslint/naming-convention */
export class BrokerOrderTypesItems {
  static TYPES_TABLE = [
    {value: '1', name: 'Market'},
    {value: '2', name: 'Limit'},
    {value: '3', name: 'Stop'},
    {value: '4', name: 'Stop limit'},
    {value: '5', name: 'Market on close'},
    {value: '6', name: 'With or without'},
    {value: '7', name: 'Limit or better'},
    {value: '8', name: 'Limit or without'},
    {value: '9', name: 'On basis'},
    {value: 'A', name: 'On close'},
    {value: 'B', name: 'Limit on close'},
    {value: 'C', name: 'Forex - Market'},
    {value: 'D', name: 'Previously quoted'},
    {value: 'E', name: 'Previously indicated'},
    {value: 'F', name: 'Forex - Limit'},
    {value: 'G', name: 'Forex - Swap'},
    {value: 'H', name: 'Forex - Previously Quoted'},
    {value: 'I', name: 'Funari'},
    {value: 'P', name: 'Pegged'},
    {value: 'Algo', name: 'Algo'}
  ];

  getLabelForValue(val: string) {
    const orderType = BrokerOrderTypesItems.TYPES_TABLE.find(el => el.value === val);
    if (orderType) {
      return orderType.name;
    } else {
      return false;
    }
  }
}
