import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';
import { SessionService } from '@core-services/session.service';
import { NavigationService } from '@core-services/navigation.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private session: SessionService, private navigation: NavigationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.session.isLoggedIn()) {
      request = request.clone({
        setHeaders: {
          Accept: 'application/json, text/plain, */*',
          Authorization: `Bearer ${this.session.getAccessToken()}`
        }
      });
      return next.handle(request).pipe(
        catchError(error => this.handleAuthError(error))
      );
    } else {
      return next.handle(request).pipe(
        retry(1)
      );
    }
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    //handle your auth error or rethrow
    if (err.status === 401 || err.status === 403) {
        //navigate /delete cookies or whatever
        this.session.logout();
        this.navigation.goTo('auth/sign-in', 'backward', true).then();
        return of(err.message);
    } else {
      return throwError(err);
    }
  }
}
