import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { dropdownAnimation } from '@animations/dropdown.animations';
import { DropDownOption, DropDownType, GroupedDropDownOption } from '@interfaces/common/form.interface';

@Component({
  selector: 'app-dropdown-options',
  templateUrl: './dropdown-options.component.html',
  styleUrls: ['./dropdown-options.component.scss'],
  animations: [dropdownAnimation()]
})
export class DropdownOptionsComponent {
  @ViewChild('wrapper')
  wrapper!: ElementRef;

  @Input() open = false;
  @Input() options: DropDownOption[] = [];
  @Input() groupedOptions: GroupedDropDownOption[] = [];

  @Input() type: DropDownType = 'list';
  @Input() minimum = 1;

  @Output() saved = new EventEmitter<DropDownOption[]>();
  @Output() closed = new EventEmitter<void>();
  @Output() cleared = new EventEmitter<void>();

  @Output() changed = new EventEmitter<DropDownOption[]>();
  @Output() selected = new EventEmitter<DropDownOption>();

  get empty(): boolean {
    if (this.type !== 'groupList') {
      return this.options.filter(e => e.selected)?.length <= 0;
    } else {
      const opt: DropDownOption[] = [];
      this.groupedOptions.forEach(group => {
        group.options.forEach(o => opt.push(o));
      });
      return opt.filter(e => e.selected)?.length <= 0;
    }

  }
  get valid(): boolean {
    return this.options.filter(e => e.selected)?.length >= this.minimum;
  }

  constructor() {}

  // @HostListener('document:click', ['$event.target'])
  // public onClick(target: any): void {
  //   const wrapperRef = this.wrapper?.nativeElement;
  //   if (wrapperRef && !wrapperRef.contains(target)) {
  //     this.open = false;
  //     this.closed.emit();
  //   }
  // }

  select(option: DropDownOption): void {
    option.selected = !option.selected;
    if (option.selected) {
      this.selected.emit(option);
      this.open = false;
      this.closed.emit();
    }
    this.changed.emit(this.options.filter(e => e.selected));
  }

  selectOne(option: DropDownOption): void {
    // this.clear();
    if (!option.disabled) {
      option.selected = true;
      this.selected.emit(option);
      this.closed.emit();
    }
  }

  save(): void {
    this.saved.emit(this.options.filter(e => e.selected));
    this.closed.emit();
  }


}
