import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {LogLevel} from "@constants/log-level";

@Pipe({
  name: 'logLevel'
})
@Injectable({
  providedIn:'root'
})
export class LogLevelPipe implements PipeTransform {

  transform(value: number): string {
    const logLevel = LogLevel.find(l => l.value == value)
    if (logLevel) {
      return logLevel.name;
    }
    return '-';
  }

}
