import {animate, AnimationTriggerMetadata, style, transition, trigger} from '@angular/animations';

export function dropdownAnimation(): AnimationTriggerMetadata {
  return trigger(
    'dropdownAnimation',
    [
      transition(
        ':enter',
        [
          style({ height: 0, opacity: 0 }),
          animate('0.3s ease-out',
            style({ height: '*', opacity: 1 }))
        ]
      ),
      transition(
        ':leave',
        [
          style({ height: '*', opacity: 1 }),
          animate('0.3s ease-in-out',
            style({ height: 0, opacity: 0 }))
        ]
      )
    ]
  );
}
