import { Injectable } from '@angular/core';
import {NavController, Platform} from '@ionic/angular';
import {DeviceType, NavigationDirection, NavigationPage} from '@interfaces/common/common.interface';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(private platform: Platform,
              private router: Router,
              private navCtrl: NavController) { }

  // public getTargetDevice(): DeviceType {
  //   if (this.platform.is('electron')) {
  //     return 'native';
  //   } else {
  //     if (this.platform.is('capacitor')) {
  //       if (this.platform.is('tablet')) {
  //         return 'tablet';
  //       } else {
  //         return 'mobile';
  //       }
  //     } else {
  //       if (this.platform.is('mobileweb')) {
  //         const t = this.platform.is('mobile');
  //         if (this.platform.is('tablet')) {
  //           return 'web-tablet';
  //         } else {
  //           return 'web-mobile';
  //         }
  //       } else {
  //           return 'desktop';
  //       }
  //     }
  //   }
  // }

  public getCurrentRoute() {
    const fullRoute = this.router.url.split('?')[0];
    const device = fullRoute.split('/')[1];
    if (device !== 'mobile' && device !== 'tablet') {
      return {device, route: fullRoute.substring(1)};
    } else {
      const route = fullRoute.split('/');
      route.splice(1, 1);
      return {device, route: route.join('/').substring(1)};
    }
  }

  public async goTo(page: NavigationPage, direction: NavigationDirection = 'forward',
                    animated: boolean = true, params: any = null): Promise<any> {
    // const device: DeviceType = this.getTargetDevice();
    // if (device) {
      let uri = `${page}`;
      // switch (device) {
      //   case 'mobile':
      //   case 'web-mobile':
      //     uri = `/mobile/${page}`;
      //     break;
      //   case 'tablet':
      //   case 'web-tablet':
      //     //TODO: Change this to /tablet/ when tablet ux is ready
      //     uri = `/mobile/${page}`;
      //     break;
      //   default:
      //     uri = `/${page}`;
      // }
      if (params) {
        uri = `${page}/${params}`;
      }
      switch (direction) {
        case 'forward':
          this.navCtrl.navigateForward(uri, {animated}).then(() => true);
          break;
        case 'backward':
          await this.navCtrl.navigateBack(uri, {animated}).then(() => true);
          break;
        case 'root':
        default:
          await this.navCtrl.navigateRoot(uri, {animated}).then(() => true);
      }
    // } else {
    //   console.error('Device Type not recognized');
    // }
  }
}
