import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {InlineSVGModule} from 'ng-inline-svg-2';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { TabsModule } from '@components/common/tabs/tabs.module';

//Forms
import { DropdownOptionsComponent } from '@components/form/dropdown-options/dropdown-options.component';
import { DropdownSelectionComponent } from '@components/form/dropdown-selection/dropdown-selection.component';
import { InputComponent } from '@components/form/input/input.component';
import { RadioGroupComponent } from '@components/form/radio-group/radio-group.component';
import { RadioComponent } from '@components/form/radio/radio.component';
import { SearchSelectionComponent } from '@components/form/search-selection/search-selection.component';
import { SingleSelectionComponent } from '@components/form/single-selection/single-selection.component';
import { SwitchComponent } from '@components/form/switch/switch.component';
import { TextareaComponent } from '@components/form/textarea/textarea.component';
import { DateComponent } from '@components/form/date/date.component';

//Common components
import { IconComponent } from '@components/common/icon/icon.component';
import { ButtonComponent } from '@components/common/button/button.component';
import { LoaderComponent } from '@components/common/loader/loader.component';
import { LoaderInlineComponent } from '@components/common/loader-inline/loader-inline.component';
import { PaginationComponent } from '@components/common/pagination/pagination.component';
import { SpinnerComponent } from '@components/common/spinner/spinner.component';
import { TabBarComponent } from '@components/common/tab-bar/tab-bar.component';
import { TableComponent } from '@components/common/table/table.component';
import { TableColumnComponent } from '@components/common/table/table-column/table-column.component';
import { ToastComponent } from '@components/common/toast/toast.component';
import { ModalComponent } from '@components/common/modal/modal.component';
import { ModalInsertionDirective } from '@directives/modal-insertion.directive';

//Modals
import { DeleteModalComponent } from '@components/modals/delete/delete.component';
import { ConfirmModalComponent } from "@components/modals/confirm/confirm.component";

//Pipes
import { DashboardSessionStatusPipe } from './pipes/dashboard-session-status.pipe';
import { LogLevelPipe } from './pipes/log-level.pipe';
import { BrokerSideColorPipe } from './pipes/broker-side-color.pipe';
import { BrokerSidePipe } from './pipes/broker-side.pipe';
import { BrokerOrderTypePipe } from './pipes/broker-order-type.pipe';
import { HackTransactionPipe } from './pipes/hack-transaction.pipe';
import { TransactionStatusColorPipe } from './pipes/transaction-status-color.pipe';
import { TransactionStatusPipe } from './pipes/transaction-status.pipe';
import { TimeInForcePipe } from './pipes/time-in-force.pipe';
import { NoValuePipe } from './pipes/no-value.pipe';
import { AlgoParamFieldTypePipe } from './pipes/algo-param-field-type.pipe';
import { YesNoPipe } from './pipes/yes-no.pipe';
import { FlowParameterFieldTypePipe } from './pipes/flow-parameter-field-type.pipe';
import {DashboardServiceHealthPipe} from "@pipes/dashboard-service-health.pipe";



@NgModule({
  declarations: [
    // Forms
    InputComponent,
    DateComponent,
    DropdownOptionsComponent,
    DropdownSelectionComponent,
    RadioComponent,
    RadioGroupComponent,
    SearchSelectionComponent,
    SingleSelectionComponent,
    SwitchComponent,
    TextareaComponent,
    //Common components
    IconComponent,
    ModalComponent,
    ButtonComponent,
    LoaderComponent,
    LoaderInlineComponent,
    PaginationComponent,
    SpinnerComponent,
    TabBarComponent,
    TableComponent,
    TableColumnComponent,
    ToastComponent,
    //Modals
    DeleteModalComponent,
    ConfirmModalComponent,
    // Directives
    ModalInsertionDirective,
    // Pipes
    DashboardSessionStatusPipe,
    LogLevelPipe,
    BrokerSideColorPipe,
    BrokerSidePipe,
    BrokerOrderTypePipe,
    TransactionStatusPipe,
    TransactionStatusColorPipe,
    HackTransactionPipe,
    TimeInForcePipe,
    NoValuePipe,
    AlgoParamFieldTypePipe,
    YesNoPipe,
    FlowParameterFieldTypePipe,
    DashboardServiceHealthPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    InlineSVGModule,
    IonicModule,
    ReactiveFormsModule,
    TranslateModule,
    TabsModule,
    NgxTippyModule,
    // LuxonModule,
],
  exports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    InlineSVGModule,
    NgxTippyModule,
    // LuxonModule,
    TabsModule,
    // Forms
    InputComponent,
    DateComponent,
    DropdownOptionsComponent,
    DropdownSelectionComponent,
    RadioComponent,
    RadioGroupComponent,
    SearchSelectionComponent,
    SingleSelectionComponent,
    SwitchComponent,
    TextareaComponent,
    //Common components
    IconComponent,
    ModalComponent,
    ButtonComponent,
    LoaderComponent,
    LoaderInlineComponent,
    PaginationComponent,
    SpinnerComponent,
    TabBarComponent,
    TableComponent,
    TableColumnComponent,
    ToastComponent,
    //Modals
    DeleteModalComponent,
    ConfirmModalComponent,
    // Directives
    ModalInsertionDirective,
    // Pipes
    DashboardSessionStatusPipe,
    LogLevelPipe,
    BrokerSideColorPipe,
    BrokerSidePipe,
    BrokerOrderTypePipe,
    TransactionStatusPipe,
    TransactionStatusColorPipe,
    HackTransactionPipe,
    TimeInForcePipe,
    NoValuePipe,
    AlgoParamFieldTypePipe,
    YesNoPipe,
    FlowParameterFieldTypePipe,
    DashboardServiceHealthPipe,
  ]
})
export class SharedModule {
  static forRoot() {
    return {
      ngModule: SharedModule
    };
  }
 }
