/* eslint-disable @typescript-eslint/naming-convention */
export class TimeInForce {
  static TYPES_TABLE = [
      {value: '0', name: 'Day (or session)'},
      {value: '1', name: 'Good Till Cancel (GTC)'},
      {value: '2', name: 'At the Opening (OPG)'},
      {value: '3', name: 'Immediate or Cancel (IOC)'},
      {value: '4', name: 'Fill or Kill (FOK)'},
      {value: '5', name: 'Good Till Crossing (GTX)'},
      {value: '6', name: 'Good Till Date'},
      {value: '7', name: 'At the Close'}
    ];
}
