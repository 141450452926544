import { Pipe, PipeTransform } from '@angular/core';
import { BrokerOrderTypesItems } from '@constants/broker-order-types';

@Pipe({
  name: 'brokerOrderType'
})
export class BrokerOrderTypePipe implements PipeTransform {

  transform(orderType: any): string {
    const brokerOrderTypes = BrokerOrderTypesItems.TYPES_TABLE;
    const orderTypeFound = brokerOrderTypes.find(e => e.value === orderType);
    if (orderTypeFound) {
      return orderTypeFound.name;
    }
    return '-';
  }

}
