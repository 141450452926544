import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {IconColor, IconImage, IconSize} from '@interfaces/common/common.interface';
import {iconRegistry} from '@constants/icons';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {

  @Input() size: IconSize = 'large';
  @Input() color: IconColor = 'brand-light';
  @Input() colorFill: IconColor = 'none';
  @Input() staticStroke = false;
  @Input() strokeWidth = '2';
  lvIcon: IconImage = 'default';
  iconPath = '';
  get icon(): IconImage {
    return this.lvIcon;
  }

  @Input() set icon(icon: IconImage) {
    this.lvIcon = icon;
    setTimeout(() => {
      this.iconPath = this.fetchIcon(this.icon);
      this.cdr.detectChanges();
    });
  }



  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (this.icon) {
      this.iconPath = this.fetchIcon(this.icon);
    }
  }

  fetchIcon(id: IconImage): string {
    const icon = iconRegistry.find((el) => el.id === id);
    if (icon) {
      return `/assets/icons/${icon.category}/${icon.id}.svg`;
    } else {
      return '';
    }
  }
}
