/* eslint-disable @typescript-eslint/naming-convention */
export class Storage {
  static SESSION = 'session';
  static SESSION_TOKEN = 'sessionToken';
  static REFRESH_TOKEN = 'refreshToken';
  static REFRESH_TOKEN_EXPIRATION = 'refreshTokenExpiration';
  static USER_DATA = 'userData';
  static VERIFY_DATA = 'sendVerificationCode';

}
