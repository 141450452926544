import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sessionStatus'
})
export class DashboardSessionStatusPipe implements PipeTransform {

  transform(status: string): string {
    return status === 'Offline' ? 'offline' : 'online';
  }

}
