import { Pipe, PipeTransform } from '@angular/core';
import { AlgoParameter } from '../constants/algo-parameter';

@Pipe({
  name: 'algoParamFieldType'
})
export class AlgoParamFieldTypePipe implements PipeTransform {

  transform(type: number): unknown {
    const fieldTypes = AlgoParameter.FIELD_TYPES;
    const typeObj = fieldTypes.find(e => e.value === type);
    if (typeObj) {
      return typeObj.name;
    }
    return '-';
  }

}
