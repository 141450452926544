import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hackTransaction'
})
export class HackTransactionPipe implements PipeTransform {

  transform(value: any, device: string = 'desktop'): any {
    let ordStatus: string;
    let transactionStatus: number;

    if (device === 'mobile') {
      ordStatus = value.ordStatus;
      transactionStatus = value.fixTransactionStatus;
    } else {
      ordStatus = value.fixTransactionStatus.data.ordStatus;
      transactionStatus = value.fixTransactionStatus.text;
    }


    if (transactionStatus === 18 || transactionStatus === 19 || transactionStatus === 20) {
      if (ordStatus === '1') {
        return 7;
      }
      if (ordStatus === '2') {
        return 3;
      }
      if (ordStatus === '4') {
        return 4;
      }
      if (ordStatus === '5') {
        return 9;
      }
      if (ordStatus === '6') {
        return 6;
      }
    }
    return transactionStatus;
  }

}
